<template>
	<div class="pay_content">
		<headerTop></headerTop>
		<!-- 主体 -->
		<div class="header bg1">
			<div class="wrap">
				<div @click="GoIndex()" class="fl">
					<img src="../../assets/image/funuo.png" alt />
					孚诺大药房
					<span>收银台</span>
				</div>
			</div>
		</div>
		<div class="content bgw">
			<div class="wrap title">
				订单提交成功，请尽快付款 订单号：{{out_trade_no}}
				<div class="fr">
					应付金额
					<span>{{money}}</span> 元
				</div>
			</div>
			<div v-show="state==0" class="wrap box">
				<div v-if="pay_type == 'alipayWeb'" class="titles">支付宝</div>
				<div v-if="pay_type == 'wechatScan'" class="titles">微信支付</div>
				<div v-if="pay_type == 'balance'" class="titles">余额支付</div>
				<div class="top"></div>
				<div class="pay_imgs">
					<div id="qrcode" ref="qrcode"></div>
				</div>
				<div class="pay_input" v-if="pay_type == 'balance'">
					<input type="password" v-model="payCode" placeholder="请输入支付密码" />
				</div>
				<div class="paytypes">
					<div v-if="pay_type == 'alipayWeb'" class="pay_txt">
						<div id="divform"></div>
						正在跳转支付请稍后
					</div>
					<div v-if="pay_type == 'wechatScan'" class="pay_txt">
						<p>请用微信扫码支付</p>
					</div>
					<div v-if="pay_type == 'UnionPay'" class="pay_txt">银联支付</div>
					<div v-if="pay_type == 'balance'" class="pay_txt">余额支付</div>
					<div v-if="pay_type == 'offline'" class="pay_txt">线下支付</div>
				</div>

				<div @click="state=1" class="tabPay">选择其他支付方式</div>
				<div class="fr confim_pay" @click="confirm1" v-if="pay_type == 'balance'">确认支付</div>
			</div>
			<div v-show="state==1" class="wrap box">
				<div class="titles mb">选择支付方式</div>
				<div @click="tabPay='wechatScan'" :class="{tabActive:tabPay=='wechatScan'}" class="tab1">
					<img v-show="tabPay!='wechatScan'" class="img1" src="@/assets/image/Cashier_Choice_Unchecked@2x.png" alt />
					<img v-show="tabPay=='wechatScan'" class="img1" src="@/assets/image/Cashier_Choice_Selection@2x.png" alt />
					<img class="img2" src="@/assets/image/Cashier_wixin_icon@2x.png" alt />
					微信支付
				</div>
				<div @click="tabPay='alipayWeb'" :class="{tabActive:tabPay=='alipayWeb'}" class="tab1">
					<img v-show="tabPay!='alipayWeb'" class="img1" src="@/assets/image/Cashier_Choice_Unchecked@2x.png" alt />
					<img v-show="tabPay=='alipayWeb'" class="img1" src="@/assets/image/Cashier_Choice_Selection@2x.png" alt />
					<img class="img2" src="@/assets/image/Cashier_zhifubao_icon@2x.png" alt />
					支付宝支付
				</div>
				<div @click="tabPay='UnionPay'" :class="{tabActive:tabPay=='UnionPay'}" class="tab1">
					<img v-show="tabPay!='UnionPay'" class="img1" src="@/assets/image/Cashier_Choice_Unchecked@2x.png" alt />
					<img v-show="tabPay=='UnionPay'" class="img1" src="@/assets/image/Cashier_Choice_Selection@2x.png" alt />
					<img class="img2" src="@/assets/image/yinlian.png" alt />
					银联支付
				</div>
				<div v-if="noYue ==1" @click="tabPay='balance'" :class="{tabActive:tabPay=='balance'}" class="tab1">
					<img v-show="tabPay!='balance'" class="img1" src="@/assets/image/Cashier_Choice_Unchecked@2x.png" alt />
					<img v-show="tabPay=='balance'" class="img1" src="@/assets/image/Cashier_Choice_Selection@2x.png" alt />
					<img class="img2" src="@/assets/image/Cashier_wallet_icon@2x.png" alt />
					余额支付
				</div>
				<div @click="onxianxia" :class="{tabActive:tabPay=='offline'}" class="tab1">
					<img v-show="tabPay!='offline'" class="img1" src="@/assets/image/Cashier_Choice_Unchecked@2x.png" alt />
					<img v-show="tabPay=='offline'" class="img1" src="@/assets/image/Cashier_Choice_Selection@2x.png" alt />
					<img class="img2" src="@/assets/image/xianxia.png" alt />
					线下支付
				</div>
				<button @click="confirm()" class="confirm">确定</button>
				<div class="Offlinebox" v-if="tabPay=='offline'">
					<p class="title">线下支付方式</p>
					<p>收款单位: {{offlineinfor.payee}}</p>
					<p>开户行: {{offlineinfor.bank}}</p>
					<p>银行账号: {{offlineinfor.bank_account}}</p>
				</div>
			</div>
		</div>
		<el-dialog title="余额支付" :visible.sync="dialogVisible" width="50%">
			<span>是否确认从余额内付款 {{money}}</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="payFily()">取 消</el-button>
				<el-button type="primary" @click="tabSend()">确 定</el-button>
			</span>
		</el-dialog>
		<div>
			<publicBottom></publicBottom>
		</div>
	</div>
</template>
<script>
	import headerTop from "@/components/public/public_headerTop.vue";
	import publicBottom from "@/components/public/public_bottom";
	import QRCode from "qrcodejs2";
	import {
		go_pay
	} from "@/request/index"; //支付
	import {
		getPayStatus
	} from "@/request/index"; //支付查询
	export default {
		components: {
			headerTop,
			publicBottom
		},
		data() {
			return {
				state: 0,
				tabPay: "",
				money: "", // 付款金额
				out_trade_no: "", //订单号
				pay_type: "", //支付方式
				pay_img: "", //跳转地址
				dialogVisible: false, // 余额支付
				checkingPay: false, //支付验证
				noYue: 1, //不使用余额支付
				payCode: "",
				offlineinfor: "",
				wxurl: "",
				zfb: "",
			};
		},
		created() {
			let datas = localStorage.getItem("lzyyToken");
			let Athis = this;
			if (datas != null) {
				this.$tokens = datas;
			} else {
				this.$message.error("登录错误，请重新登录");
				setTimeout(function() {
					Athis.$router.replace({
						path: "/land"
					});
				}, 1000);
			}
			console.log(this.$route.query);
			this.money = this.$route.query.money;
			this.out_trade_no = this.$route.query.out_trade_no;
			if(this.$route.query.pay_type=="支付宝"){
				this.pay_type="alipayWeb";
				this.tabPay="alipayWeb";
			}else{
				this.pay_type = this.$route.query.pay_type;
				this.tabPay = this.$route.query.pay_type;
			}
			console.log("支付属性",this.pay_type);
			
			if(this.$route.query.state!=undefined){
				this.state=this.$route.query.state;
			}
			let payType = this.$route.query.state;
			if (payType == 0) {
				this.noYue = 0;
			}

			this.start();
			this.init();
		},
		methods: {
			onxianxia() {
				this.tabPay = 'offline';
				this.$axios.post("/api/public/getSysconf", {
					token: this.token
				}).then(res => {
					console.log(res.data);
					if (res.data.code == 1) {
						this.offlineinfor = res.data.data;
					}
				});
			},
			init() {
				let Athis = this;
				if (this.checkingPay == false) {
					setTimeout(function() {
						getPayStatus({
							token: Athis.$tokens,
							out_trade_no: Athis.out_trade_no
						}).then(res => {
							if (res.code == 1) {
								Athis.checkingPay = true;
								Athis.$router.replace({
									path: `./payResuly?resuly=1&orderId=${Athis.out_trade_no}`
								});
							}
						});
						Athis.init();
					}, 1000);
				}
			},
			// 选择支付 的 弹出 确定按钮
			tabSend() {
				this.dialogVisible = false;
				this.starts();
			},
			//  选择 支付
			confirm() {
				this.state = 0;
				this.pay_type = this.tabPay;
				if (this.pay_type == "offline") {
					this.$router.replace({
						name: "支付结果",
						query: {
							state: 3,
							num: this.out_trade_no
						}
					});
					return;
				}
				if (this.pay_type == "balance") {
					return;
				}
				this.start();
			},
			confirm1() {
				if (this.payCode == "") {
					this.$message.error("请输入支付密码!");
					return;
				}
				this.dialogVisible = true;
			},
			//生成二维码
			qrcodeScan() {
				
				document.getElementById('qrcode').innerHTML = ''
				let qrcode = new QRCode("qrcode", {
					width: 302, // 二维码宽度
					height: 302, // 二维码高度
					text: this.pay_img
				});
			
				
			},
			//  微信 和 二维码支付 形成二维码 链接
			start() {
				console.log(this.tabPay);
				if(this.tabPay=="UnionPay"){
					this.$axios.post("/api/pay/go_pay", {
						token: this.$tokens,
						pay_type: this.tabPay,
						money: this.money,
						out_trade_no: this.out_trade_no,
						responseType: 'arraybuffer',
						deviceType:"pc",
					}).then(res => {
							window.open(res.data.data.pay_url);
					});
					return;
				}
				this.$axios.post("/api/pay/go_pay", {
					token: this.$tokens,
					pay_type: this.tabPay,
					money: this.money,
					out_trade_no: this.out_trade_no,
					responseType: 'arraybuffer',
				}).then(res => {
					if (this.tabPay == "wechatScan") {
							this.pay_img = res.data.data.qr;
							console.log(res.data.data.qr);
							this.qrcodeScan();
						
					}
					if (this.tabPay == "alipayWeb") {
						this.zfb = res.data;
						const div = document.getElementById('divform');
						div.innerHTML = res.data;
						document.body.appendChild(div);
						document.forms[0].acceptCharset = 'GBK'; //保持与支付宝默认编码格式一致，如果不一致将会出现：调试错误，请回到请求来源地，重新发起请求，错误代码 invalid-signature 错误原因: 验签出错，建议检查签名字符串或签名私钥与应用公钥是否匹配
						document.forms[0].submit();
					}
				});

			},
			payFily() {
				this.dialogVisible = false;
				// this.$router.replace({
				// 	path: `./payResuly?resuly=0&orderId=${this.out_trade_no}`
				// });
			},
			//  账户余额支付
			starts() {
				go_pay({
					token: this.$tokens,
					pay_type: this.pay_type,
					money: this.money,
					pay_password:this.$md5( this.payCode),
					out_trade_no: this.out_trade_no
				}).then(res => {
					console.log(res);
					if (res.code == 1) {
						this.$router.replace({
							path: `./payResuly?state=1&num=${this.out_trade_no}`
						});
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			GoIndex() {
				this.$router.replace({
					path: "/"
				});
			}
		}
	};
</script>
<style lang="less" scoped>
	@import "../../assets/less/index";

	.Offlinebox {
		position: absolute;
		right: 0;
		bottom: 80px;

		p {
			margin: 5px 20px !important;
		}

		.title {
			font-size: 18px;
			color: orange
		}
	}

	.paytypes {
		div {
			font-size: 16px !important;
		}

	}
</style>
